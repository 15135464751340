export default [
  {
    path: "/",
    name: "AppointentIndex",
    component: () => import("../views/public/Appointment/Index.vue"),
  },
  {
    path: "about",
    name: "AboutIndex",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/join",
    name: "JoinIndex",
    component: () => import("../views/public/Join/join.vue"),
  },
  {
    path: "confirm",
    name: "confirm",
    component: () => import("../views/public/Join/confirm.vue"),
  },
  {
    path: "registration",
    name: "registration",
    component: () => import("../views/public/Join/registration.vue"),
  },
  {
    path: "login",
    name: "LoginIndex",
    component: () => import("../views/public/Join/login.vue"),
  },
  {
    path: "trems-and-conditions",
    name: "TremsCondition",
    component: () => import("../views/public/trems_&_condition.vue"),
  },
  {
    path: "feedback",
    name: "FeedBack",
    component: () => import("../views/users/Feedback/form.vue"),
  },

  {
    path: "mentors",
    name: "MentorList",
    component: () => import("../views/public/Mentor/Index.vue"),
  },
];
