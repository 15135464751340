import mentor from "../../router/mentor.js";
import axios, {
  get_login_token,
  get_user_data,
  LOGIN_TOKEN_KEY,
  set_login_token,
  set_user_data,
} from "../axios.js";
export default {
  namespaced: true,
  state() {
    return {
      appointments: {},
    };
  },
  mutations: {
    getAppointments(state, data) {
      state.appointments = data;
    },
  },
  actions: {
    async mentorAppointment({ commit }, param = {}) {
      console.log(param);
      await axios()
        .get(`/api/mentor-appointments?schedule_id=${param.schedule_id}`, {
          params: {
            phone: param.phone,
          },
        })
        .then((response) => {
          commit("getAppointments", response.data);
          console.log("ddd", response.data);
        });
    },
  },
};
