// import axios from "../axios";
import axios from "../axios.js";

export default {
    namespaced: true,
    state () {
        return {
            list:{},
            listDetails:{},
            show:{},
            edit:{},
        }
    },
    mutations: {
        DataList(state, data)                
        {
            state.list = data;
        },
        DataListDetails(state, data)                
        {
            state.listDetails = data;
        },
        DataShow(state, data){
            state.show = data
        },
        DataEdit(state, data){
            state.edit = data
        }
    },
    actions: {
       async DataList({commit}, param = {}) {
            await axios().get('/api/legally-binding-contract')
            .then( response => {
                commit('DataList', response.data);
                console.log(response.data)
                
            });
        },
       async DataListDetails({commit}, param = {}) {
            await axios().get('/api/show-list')
            .then( response => {
                commit('DataListDetails', response.data);
                
            });
        },

        Store({commit}, param = {}){
            axios().post('/api/legally-binding-contract', param.data)
            .then( response => {
                 commit('DataList', response.data);
            });
        },

        DataEdit({commit}, param = {}){
            axios().get(`/api/legally-binding-contract/${param.id}/edit`)
            .then( response => {
                commit('DataEdit', response.data)
            })
        },

        DataShow({commit}, param = {}){
            axios().get(`/api/legally-binding-contract/${param.id}`)
            .then( response => {
                return commit('DataShow', response.data.data || '')
            })
        },

        Update({commit}, param = {}){
            axios().put(`/api/legally-binding-contract/${ param.id }`, param.data)
            .then( response => {
               return commit('DataList', response.data);
            });
        },


    }
}