
import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../axios.js";
export default {
    namespaced: true,
    state(){
        return {
            appointments: {},
            appointmentEdits: {},
            types:{},
            findUser:{},
            question_print:{},
            appointment_feedback:{}
        }
    },
    mutations:{
        getAppointments(state, appointments){
           state.appointments = appointments;
        },
        getAppointmentTypes(state, types){
            state.types = types
        },
        getAppointmentEdit(state, appointmentEdits){
            state.appointmentEdits = appointmentEdits
        },
        findUser(state, findUser){
            state.findUser = findUser
        },
        questionPrint(state, data){
            state.question_print = data
        },
        getAppointmentFeedback(state, data){
            state.appointment_feedback = data
        }
    },
    actions:{
        async findUser({commit}, param = {}){
           await axios().get('/api/find-users', {
                params:  {
                    phone: param.phone
                }
            }).then( response => {
                commit('findUser', response.data);
            })
        },
        async getAppointments({commit}, param = {}){
            await axios().get('/api/appointments', {
                params:  {
                    schedule_id: param.scheduleId
                }
            }).then( response => {
                commit('getAppointments', response.data);
            })
        },
        async getAppointmentEdit({commit}, param = {}){
            await axios().get(`/api/appointments/${param.appointmentId}/edit`)
            .then( response => {
                commit('getAppointmentEdit', response.data);
                console.log('dfsdf',response.data);

            })
        },
        async getAppointmentTypes({commit}, param = {}){
            await axios().get('/api/appointments/create', {
                params: param
            }).then( response => {
                commit('getAppointmentTypes', response.data);
            })
        },
        async questionPrint({commit}, param = {}){
            await axios().get(`api/appointments/${param.appointmentId}`).then( response => {
                commit('questionPrint', response.data);
                console.log('question',response.data);
            })
        },

        async getAppointmentFeedback({commit}, param = {}){
            await axios().get(`/api/appointment-feedback/${param.id}`)
            .then( response => {
                commit('getAppointmentFeedback', response.data);
            })
        },
    }
}