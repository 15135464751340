<template>
  <container class="text-center py-10 shadow-2xl" page-title="@heading" >
    <template #heading>
      <span class="text-blue-700" >
        401 Unauthorized
      </span>
    </template>

    <div class="w-96 mx-auto">
      <img src="../../assets/unauthorized.gif" alt="Picture">
      <router-link to="/" class="text-green-500 text-xl underline">Go Home</router-link>
    </div>
    <div class="text-3xl text-red-600">
        Sorry!! You are not allowed here!
    </div>
  </container>
</template>

<script>
import Container from "./container.vue";
export default {
  name: "NotFound",
  components: { Container }
}
</script>

<style scoped>

</style>