import axios from "../axios.js";
// import axios, {$axios, catch_handler, then_handler} from "../token";

export default {
    namespaced: true,
    state () {
        return {
            feedbacks: {},
            questionList:{},
        }
    },
    mutations: {
        getFeedbacks(state, data)                
        {
            state.feedbacks = data;
        },
        feedbackQuestion(state, data)                
        {
            state.questionList = data;
        }
    },
    actions: {
        getFeedbacks({commit}, param = {}) {
            axios().get(`/api/feedback?type=${param.data}`, {
                params:  param
              })
            .then( response => {
                commit('getFeedbacks', response.data);
                console.log(response.data)
            });
        },
        getFeedbacksQuestion({commit}, param = {}) {
            console.log(param.type)
            axios().get(`/api/feedback-questions?type=${param.type}`, {
                params:  param
              })
            .then( response => {
                commit('feedbackQuestion', response.data);
                console.log(response.data)
            });
        },
        feedbackQuestion({commit}, param = {}) {
            axios().post('/api/feedback-questions', param.data)
            .then( response => {
                commit('patientFeedbackQuestion', response.data);
                console.log(response.data);
            });
        },


    }
}