import axios, {
  get_login_token,
  get_user_data,
  LOGIN_TOKEN_KEY,
  set_login_token,
  set_user_data,
} from "../axios.js";
const API_URL = process.env.API_URL;

export default {
  namespaced: true,
  state() {
    return {
      schedules: {},
    };
  },
  mutations: {
    mentorSchedules(state, schedules) {
      state.schedules = schedules;
    },
  },
  actions: {
    mentorSchedules({ commit }, param = {}) {
      axios()
        .get(`/api/mentor-schedule?user=${param.id}`)
        .then((response) => {
          commit("mentorSchedules", response.data);
          console.log("ddd", response.data);
        });
    },
  },
};
