// import axios from "../axios";
import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../axios.js";

// import axios, {$axios, catch_handler, then_handler} from "../token";

export default {
    namespaced: true,
    state () {
        return {
            all_user: {},
            user_show:{},
            user_edit:{},
        }
    },
    mutations: {
        allUser(state, data)                
        {
            state.all_user = data;
        },
        userShow(state, data){
            state.user_show = data
        },
        userEdit(state, data){
            state.user_edit = data
        }
    },
    actions: {
       async allUser({commit}, param = {}) {
            console.log(param.page)
            await axios().get(`/api/users?page=${param.page}`)
            .then( response => {
                 commit('allUser', response.data);
                console.log( response.data)
            });
        },
       async searchUser({commit}, param = {}) {
            await axios().get(`/api/users?search=${param.search}`)
            .then( response => {
                 commit('allUser', response.data);
            });
        },

        userCreate({commit}, param = {}){
            axios().post('/api/users', param.data).then( response => {
                return commit('allUser', response.data);
            });
        },

        userEdit({commit}, param = {}){
            axios().get(`/api/users/${param.id}/edit`)
            .then( response => {
                commit('userEdit', response.data)
            })
        },

        userShow({commit}, param = {}){
            axios().get(`/api/users/${param.id}`)
            .then( response => {
                return commit('userShow', response.data.data || '')
            })
        },

        editDataSubmit({commit}, param = {}){
            axios().put(`/api/users/${ param.update_id }`, param.data).then( response => {
               return commit('allUser', response.data);
            });
        },


    }
}