<template>
  <div class="flex justify-center items-center">
    <div
      id="app"
      v-if="checkLogin"
      v-on:click="seen = !seen"
      class="relative border-b-4 border-transparent py-3"
    >
      <button class="flex justify-center items-center space-x-1 font-brand cursor-pointer">
        <div class="w-8 h-8 rounded-full overflow-hidden border border-brand">
          <img
            v-if="profile.photo"
            :src="`${profile.photo}`"
            class="w-full rounded-full p-1"
          />
          <div
            v-else
            class="w-full h-full object-cover p-1 uppercase text-brand"
          >
            {{ profileName }}
          </div>
        </div>
        <div class="font-semibold text-brand text-md hidden md:block font-brand">
          <div class="cursor-pointer">
            {{ profile.name ? profile.name : "User Name" }}
          </div>
        </div>
      </button>
      <div
        v-if="seen"
        id="hide"
        class="absolute md:w-60 px-5 py-3 bg-white rounded-lg font-brand shadow border mt-3 md:mt-5 md:right-0"
      >
        <ul class="space-y-3">
          <li class="text-left">
            {{ profile ? profile.phone : "" }}
          </li>

          <hr />
          <li class="font-medium">
            <a
              href="/"
              class="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-indigo-700"
            >
              <div class="mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                  />
                </svg>
              </div>
              Home
            </a>
          </li>
          <li class="font-medium">
            <!-- {{ profileId }} -->
            <router-link
              :to="{ name: 'profileIndex', params: { user_id: '12' } }"
              active-class="active"
              class="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-indigo-700"
            >
              <div class="mr-3">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  ></path>
                </svg>
              </div>
              My Appointment
            </router-link>
          </li>
          <hr class="dark:border-gray-700" />
          <li class="font-medium">
            <a
              @click="
                $store.dispatch('auth/logout', {
                  loggedOut: () => {
                    $router.push('/');
                  },
                })
              "
              class="cursor-pointer flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600"
            >
              <div class="mr-3 text-red-600">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  ></path>
                </svg>
              </div>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn, isAdmin } from "../../../store/axios";
export default {
  data() {
    return {
      seen: false,
    };
  },
  created() {
    this.$store.dispatch("profile/getProfile", {
      profileId: this.profileId,
    });
  },
  computed: {
    profileId() {
      return this.$store.getters["auth/user"].id || "";
    },
    checkLogin() {
      return isLoggedIn();
      //    return this.$store.getters["login/authenticated"] || ''
    },
    profile() {
      return this.$store.getters["auth/user"];
    },
    profileName() {
      const str = this.profile.name;
      return str.substring(-str.length, 2);
    },
  },
  methods: {},
};
</script>
