import NotFound from "../../src/views/public/not-found.vue";
import Unauthorized from "../../src/views/layouts/unauthorized.vue";
import { isLoggedIn, get_user_abilities, isAdmin } from "../store/axios";

export default [
  {
    path: "",
    name: "mentorScheduleIndex",
    component: () => import("../views/mentor/Schedule/Index.vue"),
  },

  {
    path: "appointment/:scheduleId",
    name: "mentorAppointmentIndex",
    component: () => import("../views/mentor/Schedule/Appointment/Index.vue"),
  },

  // {
  //   path: 'schedules',
  //   name: 'adminScheduleIndex',
  //   component: () => import('../views/admin/schedules/list.vue'),
  //   children: [
  //       {
  //           path: 'create',
  //           name: 'ScheduleCreate',
  //           component: () => import("../views/admin/schedules/form.vue"),
  //       }
  //   ],
  // },
];
