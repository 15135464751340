import axios from "../axios.js";
// import axios, {$axios, catch_handler, then_handler} from "../token";

export default {
  namespaced: true,
  state() {
    return {
      patientQuestions: {},
    };
  },
  mutations: {
    patientFeedbackQuestion(state, data) {
      state.patientQuestions = data;
    },
  },
  actions: {
    patientFeedbackQuestion({ commit }, param = {}) {
      axios()
        .get(`/api/feedback/create?appointment_id=${param.appointment_id}`, {
          params: param,
        })
        .then((response) => {
          commit("patientFeedbackQuestion", response.data);
          console.log("data", response.data);
        });
    },
    async feedbackSubmit({ commit }, param = {}) {
        return await axios().post("/api/feedback", param);
    },
  },
};
