
import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../axios.js";
// import axios, {$axios, catch_handler, then_handler} from "../token";
const API_URL = process.env.API_URL;

export default {
    namespaced: true,
    state () {
        return {
            schedules: {},
            createSchedule:{},
            editSchedule:{},
            previousHistory:{},
        }
    },
    mutations: {
        getSchedules(state, schedules)
        {
            state.schedules = schedules
        },
        createSchedule(state, createSchedule){
            state.createSchedule = createSchedule
            console.log(state.createSchedule);
        },
        getPreviousHistory(state, previousHistory){
            state.previousHistory = previousHistory
        },
        editSchedule(state, editSchedule){
            state.editSchedule = editSchedule
        }
    },
    actions: {
        getSchedules({commit}, param = {}) {
            axios().get(`/api/schedules?availability=${param.availability}&page=${param.page}`)
            .then( response => {
                commit('getSchedules', response.data);
            });
        },

        createSchedule({commit}, param = {}) {
            axios().get('/api/schedules/create', {
                params: param
              })
            .then( response => {
                commit('createSchedule', response.data);
                console.log(response.data)
            });
        },
    
        editSchedule({commit}, param = {}) {
            axios().get(`/api/schedules/${param.scheduleId }/edit`)
            .then( response => {
                console.log('editdata',response.data)
                commit('editSchedule', response.data);
            });
        },

         getPreviousHistory({commit}, param = {}) {
            axios().get('/api/previous-history', {
                params: param
              })
            .then( response => {
                commit('getPreviousHistory', response.data);
            });
        }

    }
}