import axios from "axios";

const LOGIN_TOKEN_KEY = "QwzxQwxWzxqwAcxWxzQkMugbRbTrCqIqaQnjJRq";

const USER_DATA_KEY = "yQwExWzxqwQwzCnbYwZbRbTrCqWbXReUwQzZxpR";

const ACCESS_ADMIN_ROUTE_KEY = "aCcESSQwAdMiNbRbTrCReUwRoUteA";
const ACCESS_MENTOR_ROUTE_KEY = "AccEbSQwMeNtOrwZbRbewrReUwRoUteM";

const set_admin_route = (isAdmin) => {
  return localStorage.setItem(ACCESS_ADMIN_ROUTE_KEY, isAdmin);
};

const isAdmin = () => {
  return localStorage.getItem(ACCESS_ADMIN_ROUTE_KEY);
};

// Mentor Route

const set_mentor_route = (isMentor) => {
  return localStorage.setItem(ACCESS_MENTOR_ROUTE_KEY, isMentor);
};

const isMentor = () => {
  return localStorage.getItem(ACCESS_MENTOR_ROUTE_KEY);
};

const set_login_token = (token) => {
  console.log("USER DATA TOKEN: ", token);
  if (token === false) {
    localStorage.removeItem(LOGIN_TOKEN_KEY);
  } else {
    return localStorage.setItem(LOGIN_TOKEN_KEY, token);
  }
};

const get_login_token = () => {
  return localStorage.getItem(LOGIN_TOKEN_KEY);
};

const set_user_data = (data) => {
  if (data === null) {
    localStorage.removeItem(USER_DATA_KEY);
  }
  console.log("USER DATA SETTING: ", data);

  try {
    const user = JSON.stringify(data);
    console.log("sd", user);
    localStorage.setItem(USER_DATA_KEY, user);
  } catch (e) {
    localStorage.removeItem(USER_DATA_KEY);
    console.warn("USER DATA SETTING: ", e);
  }
};

const get_user_data = () => {
  const storageUserData = localStorage.getItem(USER_DATA_KEY);

  try {
    return JSON.parse(storageUserData);
  } catch (e) {
    localStorage.removeItem(USER_DATA_KEY);
    return {};
  }
};

const isLoggedIn = () => {
  const token = localStorage.getItem(LOGIN_TOKEN_KEY);
  return token ? true : false;
};

const set_user_abilities = (abilities) => {
  return localStorage.setItem("abilities", abilities);
};

const get_user_abilities = () => {
  const abilities = localStorage.getItem("abilities") || "";

  return abilities.split(",");
};

export default function () {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${get_login_token()}`,
    },
  });
} // Alter defaults after instance has been created

export {
  set_admin_route,
  isAdmin,
  set_mentor_route,
  isMentor,
  set_login_token,
  get_login_token,
  set_user_data,
  get_user_data,
  isLoggedIn,
  get_user_abilities,
  set_user_abilities,
  LOGIN_TOKEN_KEY,
  USER_DATA_KEY,
};
