import { createApp } from 'vue'
import App from './App.vue'
import './css/output.css'
import router from './router/index'
import store from './store/index'
import Toaster from "@meforma/vue-toaster";

const app = createApp(App);

app.use(router);

app.use(store);

app.use(Toaster);

app.mount('#app')