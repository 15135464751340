import { createRouter, createWebHistory } from "vue-router";
import Layout from "../views/layouts/App.vue";
import AdminLayout from "../views/admin/layouts/App.vue";
import MentorLayout from "../views/mentor/layouts/App.vue";
import userRoute from "./user";
import publicRoute from "./public";
import doctorRoute from "./doctor";
import adminRoute from "./admin";
import mentorRoute from "./mentor";
import Unauthorized from "../../src/views/layouts/unauthorized.vue";
import { isLoggedIn, isMentor, isAdmin } from "../store/axios";

const routes = [
  {
    path: "/",
    name: "public",
    component: Layout,
    children: publicRoute,
    beforeEnter: (to, from, next) => {
      if (to.fullPath == "/join" && isLoggedIn()) {
        if (to.fullPath == "/admin" && isAdmin()) {
          return next("/admin");
        }

        console.log(to.fullPath);
        if (to.fullPath == "/mentor" && isMentor()) {
          return next("/mentor");
        }
        return next("/my");
      }
      next();
    },
  },

  {
    path: "/my",

    component: Layout,

    children: userRoute,

    beforeEnter: (to, from, next) => {
      if (isLoggedIn()) {
        return next();
      }

      return next("/");
    },
  },
  {
    path: "/admin",

    component: AdminLayout,

    children: adminRoute,

    beforeEnter: (to, from, next) => {
      if (isLoggedIn() && isAdmin()) {
        return next();
      }

      return next("/");
    },
  },

  {
    path: "/mentor",
    component: MentorLayout,
    children: mentorRoute,
    beforeEnter: (to, from, next) => {
      if (isLoggedIn() && isMentor()) {
        return next();
      }

      return next("/");
    },
  },

  //   {
  //     path:'/admin/question-print',

  //     name:'QuestionPrint',

  //     component:  () => import('../views/admin/schedules/appointments/question.vue'),

  //     beforeEnter: ( to, from, next ) => {
  //       if(isLoggedIn() && isAdmin()) {
  //         return next()
  //       }

  //       return next('/401');
  //     }

  //   },

  //   {
  //     path: '/401', //will match everything and put it under `$route.params.pathMatch`
  //     name: 'unauthorize',
  //     component: Unauthorized,
  // }
];

const router_base = (env) => {
  if (env.NODE_ENV === "development") {
    return "/";
  }
  return window.BASE || "/";
};

const router = createRouter({
  history: createWebHistory(router_base(process.env)),
  routes,
});

export default router;
