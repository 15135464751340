// import axios from "../axios";
import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../axios.js";

// import axios, {$axios, catch_handler, then_handler} from "../token";

export default {
    namespaced: true,
    state () {
        return {
            roles_list: {},
            create_role:{},
            edit_role:{},
        }
    },
    mutations: {
        roleList(state, data)                
        {
            state.roles_list = data;
        },
        roleCreate(state, data){
            state.create_role = data
        },
        roleEdit(state, data){
            state.edit_role = data
        }
    },
    actions: {
        roleList({commit}, param = {}) {
            axios().get('api/roles', {
                params:  param
              })
            .then( response => {
                commit('roleList', response.data.roles);
            });
        },
        roleCreate({commit}, param = {}){
            axios().get('/api/roles/create', param)
            .then( response => {
                commit('roleCreate', response.data)
            })
        },
        dataSubmit({commit}, param = {}){
            axios().post('/api/roles', param.data).then( response => {
                commit('roleList', response.data.roles);
            });
        },

        roleEdit({commit}, param = {}){
            axios().get(`/api/roles/${param.id}/edit`)
            .then( response => {
                commit('roleEdit', response.data)
            })
        },

        editDataSubmit({commit}, param = {}){
            axios().put(`/api/roles/${param.id}`, param.data).then( response => {
                commit('roleList', response.data.roles);
            });
        },


    }
}