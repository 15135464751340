<template>
  <container class="text-center py-10 shadow-2xl" page-title="@heading" >
    <template #heading>
      <span class="text-blue-700" >
        404 Not Found
      </span>
    </template>

    <div class="w-96 mx-auto">
      <img src="../../assets/funny-404.gif" alt="">
      <router-link to="/" class="text-green-500 text-xl underline">Go Home</router-link>
    </div>

    <div class="text-3xl text-red-600">
      Sorry, We didn't found anything on your requested url
    </div>

  </container>
</template>

<script>
import Container from "../layouts/container";
export default {
  name: "NotFound",
  components: { Container }
}
</script>

<style scoped>

</style>