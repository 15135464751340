import { data } from "autoprefixer";
import axios, {set_admin_route, get_user_abilities, set_user_abilities, set_mentor_route, get_mentor_route, get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data, isLoggedIn, isAdmin, isMentor} from "../../axios.js";
// import registration from "./registration.js";

export default {
    namespaced: true,
    state () {
        return {
            user:  get_user_data() || { },
            isLoggedIn: get_login_token() ? true : false,
            errorMessage: '',
            isAdmin: isAdmin() || {},
            isMentor: isMentor() || {},
            abilities: get_user_abilities() || [],
        }
    },
    getters:{
        authenticated( state ){
            return state.user && state.isLoggedIn 
        },
         user(state){
            return state.user
         },
         isAdmin(state){
            state.isAdmin
         },
         isMentor(state){
            state.isMentor
         }
    },
    mutations: {
        setLogin( state, data  ){
            if( data === false ) {
                state.user = false;
                state.isLoggedIn = false;
                set_login_token(false);
                set_user_data();
                set_admin_route(false);
                set_mentor_route(false);
                set_user_abilities();
            } else {

                set_login_token( data.token );
                console.log('token',data.token);
                set_user_data( data.user );
                set_admin_route( data.access.isAdmin );
                set_mentor_route( data.access.isMentor );
                set_user_abilities( data.abilities );

                console.log('Mentor', data.access.isMentor );

                state.abilities = data.abilities
                state.user = data.user
                state.isAdmin = data.access.isAdmin
                state.isMentor = data.access.isMentor
                state.isLoggedIn = true
            }
        },
        seterrorMessage(state, data){
            state.errorMessage = data;
        }
    },
    actions: {
       async login({commit}, param = {}) {

            try{
                const response = await axios().post( '/api/login', param.data );
                  if( response ) {                
                    return commit( "setLogin", response.data );
                  }
    
            } catch( r ){
                set_login_token(false);
                if( r.response ) {
                    commit( "seterrorMessage", r.response.data.message );
                    commit( "setLogin", null );       
                }
            }

            return response;
        },

        async registration({commit}, param = {}) {

            try{
                const response = await axios().post( '/api/register', param.data );
                  if( response ) {                
                     commit( "setLogin", response.data );
                  }
    
            } catch( r ){
                set_login_token(false);
                if( r.response ) {
                    commit( "setLogin", null );    
                }
            }
        },

        async logout( { commit }, params ){
            params = params || { };

            const afterThat = () => {
                commit( 'setLogin', false );
                if( typeof params.loggedOut == "function") {
                    params.loggedOut();
                }
            }

            return await axios( ).post( '/api/logout' )
                .then( () => { } )
                .catch( () => { window.location.href = '/'; } )
                .finally( afterThat );

        },
    },
    
}
