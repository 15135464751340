// import axios from "../axios";
import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../axios.js";

// import axios, {$axios, catch_handler, then_handler} from "../token";

export default {
    namespaced: true,
    state () {
        return {
            roleAssigns_list: {},
            edit_role_assign:{},
        }
    },
    mutations: {
        roleAssignList(state, data)                
        {
            state.roleAssigns_list = data;
        },
        roleAssignEdit(state, data){
            state.edit_role_assign = data
        }
    },
    actions: {
        roleAssignList({commit}, param = {}) {
            axios().get('/api/assigned-users', {
                params:  param
              })
            .then( response => {
                return commit('roleAssignList', response.data);
            });
        },
        dataSubmit({commit}, param = {}){
            axios().post('/api/role-assign', param.data).then( response =>{
                return commit('roleAssignList', response.data)
            })
        },

        roleAssignEdit({commit}, param = {}){
            axios().get(`/api/role-assign-edit/${param.id}`)
            .then( response => {
                return commit('roleAssignEdit', response.data)
            })
        },

        editDataSubmit({commit}, param = {}){
            axios().post('/api/role-assign-update', param.data).then( response =>{
                return commit('roleAssignList', response.data)
            })
        },


    }
}