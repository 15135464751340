import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../axios.js";

export default {
    namespaced: true,
    state () {
        return {
            profile: {},
        }
    },
    mutations: {
        getProfile(state, profile)
        {
            state.profile = profile
        },
    },
    actions: {
        async getProfile({commit}, param = {}) {
            await axios().get('/api/my-profile',{
                params:{ param }
            })
            .then( (response) => {
                if(response){
                    return commit('getProfile', response.data);
                }
            });
        },
    }
}