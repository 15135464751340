// import axios from "axios";
import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../../axios.js";


export default {
    namespaced: true,
    state () {
        return {
            user: {},
            confirm: {},
        }
    },
    mutations: {
        findUser(state, user)
        {
            state.user = user
        },
        confirm(state, confirm)
        {
            state.confirm = confirm
        },
    },
    actions: {

       async findUser({commit}, param = {}) {
          await axios().post( '/api/join', param.data)
            .then( response => {
                return commit('findUser', response.data);
                console.log( response.data);
            })
        },
       async confirm({commit}, param = {}) {
          await axios().post( '/api/confirm', param.data)
            .then( response => {
                commit('confirm', response.data);
                console.log( response.data);
            })
        }
    }
}
 