<template>
  <div class="w-full min-h-screen bg-custom font-brand flex flex-col justify-between">
    <nav
      class="relative flex flex-col md:flex-wrap items-center justify-between md:mx-0 bg-white shadow-md mb-3 print:hidden"
    >
      <div
        class="container px-4 mx-auto flex flex-wrap items-center justify-between"
      >
        <div
          class="w-full relative flex justify-between md:w-auto px-4 md:static md:block md:justify-between"
        >
          <router-link
            to="/"
            class="flex justify-center items-center gap-2 ml-4text-sm font-bold leading-relaxed mr-4 py-2 whitespace-nowrap uppercase text-brand"
          >
            <img class="w-10 h-10" src="../../assets/logoSIF.png" alt="" />
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90.45"
                height="15.475"
                viewBox="0 0 92.45 17.475"
              >
                <path
                  id="Path_1298"
                  data-name="Path 1298"
                  d="M19.6,0H7.95A6.364,6.364,0,0,1,2.7-2.7,9.649,9.649,0,0,1,.75-8.738,9.073,9.073,0,0,1,2.9-14.829a7.108,7.108,0,0,1,5.65-2.646H19.4v3.225H9.575a3.629,3.629,0,0,0-3.125,1.7A6.437,6.437,0,0,0,5.375-8.787a7.45,7.45,0,0,0,1,3.941,3.5,3.5,0,0,0,3.1,1.871H15.35V-7.4h-6v-2.9H19.6ZM36.675,0H28.95a8.52,8.52,0,0,1-2.325-.325A5.948,5.948,0,0,1,23.1-3.42a10.692,10.692,0,0,1-1.175-5.118,11.336,11.336,0,0,1,1.05-4.993A6.273,6.273,0,0,1,26.25-16.8a8.847,8.847,0,0,1,3.375-.674h7v3.35H29.35A2.691,2.691,0,0,0,26.775-12.2a10.344,10.344,0,0,0-.625,3.925,7,7,0,0,0,.8,3.475,2.859,2.859,0,0,0,2.6,1.675h7.125ZM56.55-11.688a5.159,5.159,0,0,1-1.791,3.954A6.12,6.12,0,0,1,50.489-6.1H43.05V0h-4.3V-17.475H50.725a5.492,5.492,0,0,1,4.425,1.921A5.844,5.844,0,0,1,56.55-11.688Zm-4.875-.224a2.3,2.3,0,0,0-.925-1.866,3.288,3.288,0,0,0-2.125-.746H43.05v5.4h5.7a2.923,2.923,0,0,0,2.075-.8A2.615,2.615,0,0,0,51.675-11.912Zm25.3,6.475A5.337,5.337,0,0,1,75.45-1.571,5.169,5.169,0,0,1,71.575,0H59.2V-3.125H69.4A2.989,2.989,0,0,0,71.125-3.6a1.741,1.741,0,0,0,.8-1.509q0-2.238-3.15-2.238h-5.15a5.972,5.972,0,0,1-3.9-1.372,4.318,4.318,0,0,1-1.675-3.491,4.854,4.854,0,0,1,1.638-3.8A6.241,6.241,0,0,1,64-17.475H75.825v3H65.4a2.239,2.239,0,0,0-1.412.584,1.739,1.739,0,0,0-.713,1.379,1.929,1.929,0,0,0,.688,1.5,2.421,2.421,0,0,0,1.662.609H71.3a6,6,0,0,1,4.162,1.359A4.61,4.61,0,0,1,76.975-5.437ZM93.2,0H85.475A8.52,8.52,0,0,1,83.15-.325a5.948,5.948,0,0,1-3.525-3.1A10.692,10.692,0,0,1,78.45-8.538,11.336,11.336,0,0,1,79.5-13.53a6.273,6.273,0,0,1,3.275-3.27,8.847,8.847,0,0,1,3.375-.674h7v3.35H85.875A2.691,2.691,0,0,0,83.3-12.2a10.344,10.344,0,0,0-.625,3.925,7,7,0,0,0,.8,3.475,2.859,2.859,0,0,0,2.6,1.675H93.2Z"
                  transform="translate(-0.75 17.475)"
                  fill="#02a8a8"
                />
              </svg>
            </span>
          </router-link>
          <button
            class="text-brand cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
            type="button"
            @click="toggleNavbar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div
          :class="{ hidden: !showMenu, flex: showMenu }"
          class="w-full md:w-auto md:flex md:flex-grow items-center"
        >
          <ul class="flex flex-col md:flex-row list-none ml-auto gap-4">
            <li class="nav-item">
              <div
                class="z-40 p-4 transition ease-in-out delay-100 hover:translate-y-1 hover:scale-110 text-brand duration-300"
              >
                <router-link
                  to="/"
                  :class="{
                    'border-b-4 border-brand transform transition duration-300 p-2':
                      this.$route.fullPath === '/',
                  }"
                  class="text-brand"
                  >Appointment</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <div
                class="z-40 p-4 transition ease-in-out delay-100 hover:translate-y-1 hover:scale-110 text-brand duration-300"
              >
                <router-link
                  to="/about"
                  :class="{
                    'border-b-4 border-brand transform transition duration-300 p-2':
                      this.$route.fullPath === '/about',
                  }"
                  class="text-brand"
                >
                  About Us</router-link
                >
              </div>
            </li>
            <li class="nav-item z-50">
              <div class="z-40 md:p-0 text-brand rounded-md duration-200">
                <layout-profile />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <router-view />
    <layoutFooter />
  </div>
</template>

<script>
import layoutProfile from "./profile.vue";
import layoutFooter from "./footer.vue";

export default {
  name: "PublicLayout",
  components: { layoutFooter, layoutProfile },
  data() {
    return {
      showMenu: false,
    };
  },

  methods: {
    toggleNavbar() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
