
import NotFound from "../../src/views/public/not-found.vue";
import Unauthorized from "../../src/views/layouts/unauthorized.vue";
import { isLoggedIn, get_user_abilities, isAdmin } from '../store/axios';

export default [
      {
        path: 'schedules',
        name: 'adminScheduleIndex',
        component: () => import('../views/admin/schedules/list.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Schedule List') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },

      {
        path: 'schedule-create',
        name: 'ScheduleCreate',
        component: () => import('../views/admin/schedules/form.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Schedule Create') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },

      {
        path: 'schedule/:scheduleId/edit',
        name: 'ScheduleEdit',
        component: () => import('../views/admin/schedules/edit.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Schedule Edit') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },

// User 

      {
        path: 'users',
        name: 'UserList',
        component: () => import('../views/admin/users/list.vue'),
  
      },
      {
        path: 'users/create',
        name: 'UserCreate',
        component: () => import('../views/admin/users/create.vue'),
      },
      {
        path: 'users/:id/edit',
        name: 'UserEdit',
        component: () => import('../views/admin/users/edit.vue'),
      },
      {
        path: 'users/:id',
        name: 'UserShow',
        component: () => import('../views/admin/users/show.vue'),
      },
      // {
      //   path: 'users/:id',
      //   name: 'UserList',
      //   component: () => import('../views/admin/users/show.vue'),
      //   children: [
      //       {
      //           path: 'create',
      //           name: 'ScheduleCreate',
      //           component: () => import("../views/admin/schedules/form.vue"),
      //       }
      //   ],
      // },



 // Appointment

      {
        path: 'schedules/:scheduleId/appointments',
        name: 'adminAppointmentIndex',
        component: () => import('../views/admin/schedules/appointments/list.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Appointment List') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },
      {
        path: 'question-print/:appointmentId',
        name: 'questionPrint',
        component: () => import('../views/admin/schedules/appointments/question.vue'),
        // beforeEnter: ( to, from, next ) => {
        //   if(get_user_abilities().includes('Appointment List')){
        //     return next()
        //   }
        //   return next('/401')
        // }
      },

      {
        path: 'schedules/:scheduleId/appointments/:appointmentId',
        name: 'adminAppointmentEdit',
        component: () => import('../views/admin/schedules/appointments/edit.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Appointment Edit') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },

      {
        path: '/appointments/:scheduleId/create',
        name: 'adminAppointmentCreate',
        component: () => import('../views/admin/schedules/appointments/form.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Appointment Create') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },
      
      {
        path: '/appointments/:scheduleId/feedbacks/:appointmentId',
        name: 'adminFeedbackIndex',
        component: () => import('../views/admin/schedules/appointments/feedbacks/list.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Feedback List') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },
      
      {
        path: 'feedback-question/create',
        name: 'adminFeedbackQuetion',
        component: () => import('../views/admin/schedules/appointments/feedbacks/form.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Feedback Questions Create') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },
  

 // Role 

      {
        path: 'roles',
        name: 'roleList',
        component: () => import('../views/admin/roles/list.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Role List') || get_user_abilities().includes('*')){
            return next()
          }
          return next('/401')
        }
      },
      {
        path: 'roles/create',
        name: 'roleCreate',
        component: () => import('../views/admin/roles/form.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Role Create') || get_user_abilities().includes('*')){
            return next()
          }
          return next('401')
        }
      },
      {
        path: 'roles/:id/edit',
        name: 'roleEdit',
        component: () => import('../views/admin/roles/edit.vue'),
        beforeEnter: ( to, from, next ) => {
          if(get_user_abilities().includes('Role Edit') || get_user_abilities().includes('*')){
            return next()
          }
          return next('401')
        }
      },


// Role Assign

      {
        path: 'role-assign',
        name: 'roleAssign',
        component: () => import('../views/admin/role-assign/list.vue'),
      },
      {
        path: 'role-assign/create',
        name: 'roleAssignCreate',
        component: () => import('../views/admin/role-assign/form.vue'),
      },
      {
        path: 'role-assign/:id',
        name: 'roleAssignEdit',
        component: () => import('../views/admin/role-assign/edit.vue'),
      },

// Legally Binding Contract

      {
        path: 'legally-binding-contract',
        name: 'LegalltBindingContractList',
        component: () => import('../views/admin/legally-binding-contract/list.vue'),
      },
      {
        path: 'legally-binding-contract/create',
        name: 'LegalltBindingContractCreate',
        component: () => import('../views/admin/legally-binding-contract/create.vue'),
      },
      {
        path: 'legally-binding-contract/:id',
        name: 'LegalltBindingContractEdit',
        component: () => import('../views/admin/legally-binding-contract/edit.vue'),
      },

      // {
      //   path: 'legally-binding-contract/:id',
      //   name: 'LegalltBindingContractShow',
      //   component: () => import('../views/admin/legally-binding-contract/edit.vue'),
      // },


      // Feedback 

      {
        path: 'feedbacks',
        name: 'FeedbackList',
        component: () => import('../views/admin/feedbacks/list.vue'),
      },

      {
        path: 'feedbacks/create',
        name: 'FeedbackCreate',
        component: () => import('../views/admin/feedbacks/form.vue'),
      },





    {
        path: '/:pathMatch(.*)*', //will match everything and put it under `$route.params.pathMatch`
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/401', //will match everything and put it under `$route.params.pathMatch`
        name: 'unauthorize',
        component: Unauthorized,
    }
      
]
