<template>
  <div class="absolute inset-0 flex justify-center items-center" :class="`${background} ${opacity}`">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: "overly",
  props: {
    background: { type:String, default: "bg-gray-700" },
    opacity: { type:String, default: "bg-opacity-75" }
  }
}
</script>

<style scoped>

</style>