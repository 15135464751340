import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      count: 0,
      colorCode: "red",
    };
  },

  mutations: {
    increment(state) {
      state.count++;
    },

    colorCode(state, newValue) {
      return (state.colorCode = newValue);
    },
  },

  actions: {
    colorCode({ commit }, newValue) {
      commit("colorCode", newValue);
    },
  },

  modules: {
    auth: require("./modules/user/login").default,
    users: require("./admin-modules/users").default,
    join: require("./modules/user/join").default,
    schedule: require("./modules/schedule").default,
    profile: require("./modules/profile").default,
    adminSchedule: require("./admin-modules/schedule").default,
    adminAppointment: require("./admin-modules/appointment").default,
    adminFeedback: require("./admin-modules/feedback").default,
    feedback: require("./modules/feedback").default,
    roles: require("./admin-modules/roles").default,
    roleAssign: require("./admin-modules/role-assign").default,
    legally_binding_contract:
      require("./admin-modules/legally-binding-contract").default,

    mentor: require("./mentor-modules/schedule").default,

    mentors: require("./modules/mentor").default,

    mentor_appointment: require("./mentor-modules/appointment").default,
  },
});

export default store;
