export default [
  {
    path: "my-profile",
    name: "profileIndex",
    component: () => import("../views/users/Profile/Index.vue"),
  },
  {
    path: "my-profile/:id/edit",
    name: "profileEdit",
    component: () => import("../views/users/Profile/edit.vue"),
  },
];
