<template>
  <div class="flex justify-center items-center p-3">
    <div
      id="app"
      v-if="checkLogin"
      @click="seen = !seen"
      class="relative border-b-4 border-transparent"
    >
      <div class="flex justify-center items-center space-x-1 cursor-pointer">
        <div
          class="w-8 h-8 rounded-full overflow-hidden flex justify-center items-center border border-brand"
        >
          <img
            v-if="profile.photo"
            :src="`${profile.photo}`"
            class="w-full rounded-full p-1"
          />
          <span v-else class="p-1 uppercase font-semibold text-brand font-brand">{{
            profileName
          }}</span>
          <!-- <img  src="../../assets/image/user.svg" alt="" class="w-full h-full object-cover bg-white p-1"> -->
        </div>
        <div
          class="font-semibold text-brand font-brand text-md hidden md:flex md:justify-center md:items-center"
        >
          <button class="cursor-pointer">
            {{
              $store.getters["auth/user"]
                ? $store.getters["auth/user"].name
                : "User Name"
            }}
          </button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div
        v-if="seen"
        id="hide"
        class="absolute md:w-60 px-5 py-3 font-brand bg-white rounded-lg border mt-3 md:mt-5 right-0"
      >
        <ul class="space-y-3">
          <li class="text-left">
            {{
              $store.getters["auth/user"]
                ? $store.getters["auth/user"].phone
                : ""
            }}
          </li>
          <hr />
          <li class="font-medium bg-white">
            <router-link
              :to="{ name: 'profileIndex', params: { user_id: 11 } }"
              class="flex items-center transform transition-colors duration-200 border-r-4 border-transparent"
              :class="{
                'bg-brand p-2 rounded-md text-white':
                  this.$route.fullPath == '/my/my-profile',
              }"
            >
              <div class="mr-3">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  ></path>
                </svg>
              </div>
              My Appointment
            </router-link>
          </li>
          <li v-if="isAdmin == 1" class="font-medium">
            <router-link
              to="/admin/schedules"
              :class="{
                'bg-brand p-2 rounded-md text-white': url == '/admin/schedules',
              }"
              class="flex items-center transform transition-colors duration-200 border-r-4 border-transparent"
            >
              <div class="mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                  />
                </svg>
              </div>
              Admin Dashboard
            </router-link>
          </li>
          <li v-if="isMentor == 1" class="font-medium">
            <router-link
              to="/mentor/"
              :class="{
                'bg-brand p-2 rounded-md text-white': url == '/admin/',
              }"
              class="flex items-center transform transition-colors duration-200 border-r-4 border-transparent"
            >
              <div class="mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                  />
                </svg>
              </div>
              Mentor Dashboard
            </router-link>
          </li>

          <hr class="" />
          <li class="font-medium">
            <a
              @click="
                $store.dispatch('auth/logout', {
                  loggedOut: () => {
                    $router.push('/');
                  },
                })
              "
              class="cursor-pointer flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600"
            >
              <div class="mr-3 text-red-600">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  ></path>
                </svg>
              </div>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="font-semibold text-brand text-md">
        <router-link
          to="/join"
          class="flex justify-center items-center space-x-1 cursor-pointer"
        >
          <div
            class="w-8 h-8 rounded-full overflow-hidden hidden md:block border border-brand"
          >
            <img
              src="../../assets/image/user.svg"
              alt=""
              class="w-full h-full object-cover p-1"
            />
          </div>
          <span>Join</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn, isAdmin, isMentor } from "../../store/axios";
export default {
  data() {
    return {
      // open:false
      seen: false,
      login: false,
    };
  },
  created() {
    this.$store.dispatch("profile/getProfile", {
      profileId: this.profileId,
    });
  },
  computed: {
    profileId() {
      return this.$store.getters["auth/user"].id || "";
    },
    checkLogin() {
      return this.$store.state.auth.isLoggedIn;
    },
    isAdmin() {
      return isAdmin();
    },
    isMentor() {
      return isMentor();
    },
    profile() {
      return this.$store.getters["auth/user"];
    },
    profileName() {
      const str = this.profile.name;
      return str.substring(-str.length, 2);
    },
  },
  methods: {},
};
</script>
