
import axios, { get_login_token, get_user_data, LOGIN_TOKEN_KEY, set_login_token, set_user_data} from "../axios.js";

export default {
    namespaced: true,
    state () {
        return {
            schedules: {},
            previousHistory:{},
        }
    },
    mutations: {
        getSchedules(state, schedules)
        {
            state.schedules = schedules
        },
        getPreviousHistory(state, previousHistory)
        {
            state.previousHistory = previousHistory
        },
    },
    actions: {

        async getSchedules({commit}, param = {}) {
            await axios().get(`/api/search-schedule/${param.support_type_id}`,{
                params: param
            })
            .then( response => {
                commit('getSchedules', response.data);
                console.log('getSchedules', response.data);
            });
        },

         getPreviousHistory({commit}, param = {}) {
            axios().get('/api/previous-history?user_id=2', {
                params: param
              })
            .then( response => {
                commit('getPreviousHistory', response.data);
            });
        }

    }
}