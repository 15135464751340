import axios, {
  get_login_token,
  get_user_data,
  LOGIN_TOKEN_KEY,
  set_login_token,
  set_user_data,
} from "../axios.js";

export default {
  namespaced: true,
  state() {
    return {
      mentor_list: {},
    };
  },
  mutations: {
    getMentor(state, data) {
      state.mentor_list = data;
    },
  },
  actions: {
    async getMentor({ commit }, param = {}) {
      await axios()
        .get("/api/mentor-list", {
          params: { param },
        })
        .then((response) => {
          if (response) {
            return commit("getMentor", response.data);
          }
        });
    },
  },
};
